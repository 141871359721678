:root {
  --red: #ed1f24;
}

* {
  box-sizing: border-box;
  margin: 0;
  outline: none;
  padding: 0;
  font-family: 'Lato','Arial', 'Helvetica', sans-serif;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}

body {
  position: relative;
}

#root,
main {
  width: 100%;
}

.header-admin {
  background: black;
  border-top: 1px solid red;
  padding: 20px 0;
  width: 100%;
}

.header-admin img {
  display: block;
  margin: 0 auto;
  width: 250px;
}

.footer-admin {
  background: black;
  margin: 40px 0 0 0;
  padding: 20px 0;
  width: 100%;
}


/**
 * Loading
 */

.loading {
  margin: 0 auto;
  padding: 40px 0;
  text-align: center;
  width: 300px;
}

.loading i {
  font-size: 30px;
}

.loading div {
  font-size: 16px;
  padding-top: 15px;
}

.loading-overlay {
  background: rgba(255,255,255,0.9);
  height: 100vh;
  position: fixed;
  text-align: left;
  width: 100vw;
  z-index: 99999;
}

.loading-overlay div {
  left: 50%;
  position: absolute;
  top: 45%;
  transform: translate(-50%, -50%);
  width: 250px;
}

.loading-overlay div i {
  color: #222;
  font-size: 125px;
}

.loading-overlay div span {
  font-size: 24px;
}


.info {
  background: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 5px;
  color: #856404;
  font-size: 12px;
  padding: 10px;
  width: 100%;
}



/**
 * Header
 */ 
#header {
  width: 100%; 
  background: none;
  border-top: 2px solid var(--red);
  height: 150px;
  position: absolute;
  z-index: 3;
}
#header > div {
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
}
#header > div h1 {
  display: inline-block;
  width: 300px;
}
.logo {
  width: 300px;
}
a, a:link, a:visited, a:active {
  color: var(--red);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a[rel=home], a[rel=home]:link, a[rel=home]:visited, a[rel=home]:active {
  color: white;
  font-weight: lighter;
  font-size: 18px; 
}
nav {
  float: right;
  margin-top: 45px;
}
nav a,
nav a:link, 
nav a:visited, 
nav a:active,
nav button {
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
}

nav button {
  padding-left: 20px;
  padding-right: 20px;
}
nav a {
  padding: 10px 20px;
}

/**
 * Dropdown
 */

.dropdown {
  border-radius: 0 0 5px 5px;
  display: inline-block;
  position: relative;
}

.dropdown-btn {
  cursor: pointer;
  background: none;
  border: 0;
}

.dropdown-btn-hover {
  background-color: rgba(255,255,255,0.35);
}

.dropdown .dropdown-btn .header-avatar {
  margin-right: 1px;
  width: 18px;
}


nav > a:hover,
nav button:hover {
  background-color: rgba(255,255,255,0.35);
}

.dropdown-btn i { 
  margin-left: 5px; 
}

.dropdown .menu {
  background-color: rgba(255,255,255,0.35);
  border: 1px solid rgba(255,255,255,0.5);
  border-top: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  display: none;
  margin: 0;
  position: absolute;
  width: 330px;
  z-index: 3;
}

.dropdown #games-menu {
  font-weight: bold;
  left: 0px;
}

.dropdown #user-menu {
  left: -110px;
}

.dropdown #user-menu button {
  background: none;
  border: 0;
  border-radius: 0 0 5px 5px;
  cursor: pointer;
  font-weight: bold;
  height: 60px;
  text-transform: capitalize;
  width: 100%;
}

.dropdown #user-menu button:hover {
  background: rgba(0,0,0,0.5);
}

.dropdown #login-menu {
  left: -110px;
  padding: 20px; 
}

.dropdown #login-menu input {
  background: rgba(255,255,255,0.7);
  border: 1px solid #666;
}

.dropdown #login-menu input:focus {
  background: rgba(255,255,255,0.9);
  outline: none;
}

.dropdown #login-menu > div a {
  background-color: #222;
  cursor: pointer;
  display: block;
  margin-top: 5px;
  padding: 10px 0;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}
.dropdown #login-menu > div a:hover {
  background-color: #444;
  text-decoration: none;
}

.login-form-dropdown button {
  cursor: pointer;
  background: var(--red);
  border: 0;
}
.login-form-dropdown button:hover {
  background: maroon;
}

.login-form-dropdown > div {
  position: relative;
  padding: 10px 0;
}

.login-form-dropdown > div input {
  height: 15px;
  position: absolute;
  top: 12.5px;
  width: 15px;
}

.login-form-dropdown > div span {
  margin-left: 20px;
}

.dropdown .item-lg {
  border-bottom: 1px solid rgba(255,255,255,0.5);
  overflow: hidden;
  padding: 20px;
  width: 100%;
}

.dropdown .item-lg div {
  color: white;
  float: left;
  width: 60px;
}

.dropdown .item-lg div img {
  border: 1px solid rgba(255,255,255,0.5);
  width: 100%;
}

.dropdown .item-lg div:last-child {
  font-size: 24px;
  margin-left: 20px;
  padding-top: 15px;
}

.dropdown .item {
  border-bottom: 1px solid rgba(255,255,255,0.5);
  clear: both;
  height: 60px;
  width: 100%;
}

.dropdown .item:last-child {
  border-bottom: 0;
}

.dropdown .item button {
  color: white;
  display: block;
  font-size: 16px;
  padding: 20px;
  text-align: left;
  text-transform: capitalize;  
  width: 100%;
}

.dropdown .item a {
  color: white;
  display: block;
  font-size: 16px;
  font-weight: bold;
  padding: 20px;
  text-transform: capitalize;  
  width: 100%;
}
.dropdown .item a:hover {
  background-color: rgba(0,0,0,0.5);
  text-decoration: none;
}
.dropdown .item:last-child,
.dropdown .item:last-child a:hover {
  border-radius: 0 0 5px 5px;
}

.donate-btn {
  padding-left: 11px;
  padding-right: 11px;
}
.donate-btn:hover {
  padding-bottom: 10.5px;
}

.donate-btn i {
  font-size: 14px;
  margin-left: 5px;
}

.dropdown #user-btn {
  padding-right: 39px;
  padding-left: 39px;
}


/**
 * Mobile Menu
 */

.mobile-menu-button {
  display: none;
}
#mobile-menu {
  height: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0, 0.9);
  overflow-x: hidden; 
  transition: 0.5s;
}
.mobile-menu-content {
  position: relative;
  top: 25%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
#mobile-menu a {
  padding: 8px;
  text-decoration: none;
  font-size: 36px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
#mobile-menu a:hover, #mobile-menu a:focus {
  color: #f1f1f1;
}
#mobile-menu .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}



/**
 * Footer
 */ 

#footer {
  background: black;
  clear: both;
  color: white;
  padding: 40px 0;
  margin: 0 auto;
  text-align: center;
  width: 100%;

}
#footer > img {
  width: 200px;
  margin-bottom: 40px;
}
#footer a {
  font-size: 14px;
  color: #999;
}
#footer small {
  display: block;
  margin-top: 10px;
}
.sections {
  display: flex;
  flex-flow: row wrap;
  width: 1280px;
  margin: 0 auto;
}


/*===========================================================*/

.topsection {
  background: black;
  display: flex;
  height: 100%;
  justify-content: center;
  margin-bottom: 40px;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.topsection div img {
  margin: 0 auto;
  max-height: 100vh;
  width: 100%;
}
.topsection div .cta { 
  color: white;
  font-size: 22px;
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  text-align: center;
  text-shadow: 1px 1px black;
  top: 40%;
  width: 100%;
  z-index: 99;
}
.topsection div .cta div:first-child {
  font-size: 32px;
  font-size: 42px;
  margin-bottom: 20px;
}
.topsection div .cta .play-button {
  text-align: center;
  width: 100%;
}
.topsection div .cta .play-button i {
  cursor: pointer;
  font-size: 100px;
}
.topsection div .cta .purchase-game {
  margin-top: 200px;
}
.topsection div .cta .purchase-game p {
  margin-bottom: 25px; 
}
.topsection div .cta .purchase-game a {
  color: white;
  background-color: rgba(0,0,0,0.5);
  border: 2px solid white;
  border-radius: 5px;
  padding: 10px 20px;
}
.topsection div .cta .purchase-game a:hover {
  text-decoration: none;
}
.overlay {
  background-color: rgba(0,0,0,0.3);
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 50%,rgba(0,0,0,0.65) 75%,rgba(0,0,0,0.65) 100%);
  bottom: 0;
  display: block;
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}


/**
 * FORMS
 */

h4 {
  border-bottom: 2px solid red;
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 10px 0;
}

.g-recaptcha {
  margin: 20px 0;
}

#g-recaptcha-response {
    display: block !important;
    position: absolute;
    margin: -78px 0 0 0 !important;
    width: 302px !important;
    height: 76px !important;
    z-index: -999999;
    opacity: 0;
}

.admin-login-form {
  margin: 0 auto;
  padding: 200px 0;
  width: 500px;
}

.form-background {
  height: 100%;
  width: 100%; 
}

.user-form {
  background: #eee;
  margin: 40px auto;
  padding: 20px 40px 40px 40px;
  max-width: 600px;
}

.buffer-space {
  margin-top: 200px;
  margin-bottom: 200px;
}

.user-form label {
  font-weight: bold;
}

.user-form input {
  margin-top: 10px;
}

.user-form p:nth-child(3) {
  color: gray;
  font-size: 14px;
  margin: -10px 0 20px 0;
}

.user-form label[for=keep-me-logged-in] {
  cursor: pointer;
  position: relative;
}

.user-form label[for=keep-me-logged-in] span {
  margin-left: 25px;
}

.user-form input[type=checkbox] {
  cursor: pointer;
  height: 15px; 
  left: 2px;
  margin-top: 0;
  position: absolute;
  top: 2.7px;
  width: 15px;
}



.user-form button {
  background: var(--red);
  border: 0;
  color: white;
  cursor: pointer;
  font-weight: bold;
  margin: 30px 0;
  text-transform: uppercase;
  width: 100%;
}

.user-form button:hover {
  background: maroon;
}

.user-form div:last-child {
  display: flex;
  font-size: 15px;
  justify-content: space-between;
}

.user-form div:last-child a {
  color: red;
}

.news-create-form {
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  padding: 100px 15px 15px 15px;
  width: 800px;
}

.news-update-form {
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  padding: 10px;
  width: 600px;
}

.change-temp-password-form {
  background: white;
  border-radius: 5px;
  margin: 0 auto;
  padding: 10px;
  width: 500px;
}

.errors {
  background: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 2px;
  color: #721c24;
  padding: 10px 5px;
  margin: 5px 0;
}


fieldset {
  margin: 0 0 10px 0;
  padding: 20px 20px 10px 20px;
}

legend {
  padding: 0 5px;
}

input {
  height: 40px;
  margin: 0 0 20px 0;
  padding: 0 10px;
  width: 100%;
}

.social-media > div {
  width: 200px;
  padding: 10px;
  
}

.social-media div:first-of-type {
  width: auto;
  padding: 0;
}

.social-media p {
  margin-bottom: 10px;
}

.social-media input {
  display: inline-block;
  height: auto;
  margin: 0 10px 0 -10px;
  width: auto;
}

.social-media label {
  text-align: left;
  display: inline-block;
}

.image-upload {
  display: flex;
}

.image-upload label {
  font-size: 15px;
  width: 118px;
}

.image-upload input {
  margin: 0;
}

.video-upload {
  display: flex;
}

.video-upload label {
  width: 210px;
}



button {
  cursor: pointer;
  height: 40px;
  width: 100%;
}

textarea {
  height: 200px;
  margin: 0 0 20px 0;
  padding: 10px;
  width: 100%;
}


/**
 * Modal
 */
.modal {
  background: rgba(0, 0, 0, 0.9);
  display: none;
  height: 100%;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.modal-content {
  width: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.youtube-modal {
  height: 720px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1280px;
}

.close-btn {
  background: none;
  border: 0;
  color: #999;
  cursor: pointer;
  font-size: 38px;
  position: absolute;
  right: 40px;
  top: 40px;
  width: 60px;
  z-index: 99999;
}

.close-btn:hover {
  color: white;
}


/**
 * Sections
 */
.sections {
  display: flex;
  flex-flow: row wrap;
  width: 1280px;
  margin: 0 auto 40px auto;
}




/**
 * Reviews
 */

.reviews-section {
  background: #1b2838;
  height: 600px;
  margin-bottom: 80px;
  padding: 40px 0;
  width: 100%;
}

.reviews-section div {
  height: 100%;
  margin: 0 auto;
  overflow: scroll;
  width: 650px;
}

.reviews-section div:hover {
  cursor: ns-resize;
}

.reviews-section div img {
  width: 100%;
}





/**
 * News
 */

.article {
  width: 400px;
  margin: 40px 40px 40px 0;
  background: white;
}
.article:nth-of-type(3n) {
  margin-right: 0;
}
.article figure {
  cursor: pointer;
  border: 1px solid gray;
  width: 100%;
  height: 200px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  
}
.article img {
  width: 100%;
}
.article figcaption {
  width: 100%;
  color: #eee;
  background: rgba(0,0,0,0.75);
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.article h2 {
  font-size: 24px;
  font-weight: lighter;
  margin: 20px 0 5px 0;
}
.article address, .article time {
  display: inline-block;
  margin-bottom: 20px;
  font-size: 14px;
}
.article p {
  font-size: 16px;
  line-height: 1.2;
  width: 100%;
}
.fold {
  display: none;
}
.show-fold {
  display: block;
}
.toggle-fold {
  display: inline;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}
.article a,
.article a:link,
.article a:visited,
.article a:active {
  color: red;
  text-decoration: none;
}
.article a:hover {
  color: maroon;
  text-decoration: underline;
}

.pagination-nav {
  width: 100%;
}

.pagination-nav button {
  background: none;
  border: 2px solid black;
  color: black;
  cursor: pointer;
  font-size: 12px; 
  margin: 0;
  padding: 9px 0 10px 0;
  text-transform: uppercase;
  width: 90px;  
}

.pagination-nav button:hover {
  background: black;
  color: white;
}




/**
 * Admin edit buttons
 */


.admin-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  padding: 20px 0 0 0;
  width: 650px;
}


/**
 * Tooltip
 */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 115%;
  left: 50%;
  margin-left: -60px;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}


.admin-btn-container button {
  border: 1px solid #222;
  border-radius: 5px;
  cursor: pointer;
  height: 40px;
  margin: 0 0 0 10px;
  padding: 5px;
  width: 40px;
}

.admin-btn-container button i {
  font-size: 20px;
}

.publish-btn, .unpublish-btn {
  background: #5cb85c;
}

.unpublish-btn {
  background: #d9534f;
}

.update-btn {
  background: #f0ad4e;
}

.delete-btn {
  background: #d9534f;
}

.individual-article {
  background: white;
  width: 100%;
}

.individual-article-overlay {
  background-color: rgba(0,0,0,0.3);
  background: radial-gradient(ellipse at center, rgba(0,0,0,0) 50%,rgba(0,0,0,0.65) 75%,rgba(0,0,0,0.65) 100%);
  bottom: 0;
  display: block;
  height: 100vh;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 2;
}

.individual-article figure {
  border: none;
  height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.individual-article img {
  width: 100%;
}

.individual-article figcaption {
  width: 100%;
  color: #eee;
  background: rgba(0, 0, 0, 0.75);
  font-size: 18px;
  padding: 5px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.individual-article figcaption div {
  margin: 0 auto;
  max-width: 650px;
}

.individual-article header {
  max-width: 650px;
  margin: 40px auto;
}

.individual-article h2 {
  font-size: 40px;
  font-weight: lighter;
  margin: 20px 0;
}

.individual-article address,
.individual-article time {
  display: inline-block; 
  font-size: 18px;
}

.individual-article > div {
  max-width: 650px;
  margin: 0 auto;
}

.video-container {
  height: 400px;
  margin-bottom: 20px;
  width: 100%;
}

.individual-article p {
  font-size: 18px;
  line-height: 1.6;
  white-space: pre-wrap;
  width: 100%;
}

.individual-article a {
  color: red;
  text-decoration: none;
}

.individual-article a:hover {
  color: maroon;
}

.individual-article footer {
  border-bottom: 2px solid black;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 40px 0;
  max-width: 650px;
}

.individual-article footer div:first-child i {
  font-size: 30px;
}


.individual-article footer span {
  margin-right: 20px;
}

.individual-article footer a {
  font-size: 30px;
  margin: 0 5px;
}

.individual-article footer div a .fa-facebook {
  color: #3b5998;
}

.individual-article footer div a .fa-twitter {
  color: #1da1f2;
}

.individual-article footer div a .fa-reddit {
  color: #ff5700;
}

.comments-section {
  clear: both;
  margin: 40px auto;
  max-width: 650px;
}

.comments-section h3 {
  margin-bottom: 40px;
}

.comments-section aside,
.comments-section aside form {
  width: 100%;
}


.comments-section aside, 
.comments-section > p {
  margin-top: 40px;
}

.comments-form {
  background: black;
  border: 1px solid black;

  padding: 20px 20px 30px 20px;
}

.comments-form textarea {
  border: 2px solid #555;
  height: 100px;
  width: 100%;
}

.comments-form .comment-submit-btn {
  background: var(--red);
  border: 0;
  color: white;
  cursor: pointer;
  float: right;
  width: 200px;
}

.comments-form .comment-submit-btn:hover {
  background: red;
}

.comments-form div:last-child {
  position: relative;
}

.comments-form .emoji-container {
  background: white;
  border: 2px solid var(--red);
  visibility: hidden;
  padding: 5px 0;
  position: absolute;
  height: 45px;
  width: 0;
  top: 0;
  transition: width .5s, visibility .4s; 
}

.comments-form .emoji-container img {
  cursor: pointer;
  padding: 0 5px;
  width: 40px;
  visibility: hidden;
}


.comments-form .create-link-btn,
.comments-form .emoji-btn {
  background: none;
  border: 0;
  color: #999;
  cursor: pointer;
  font-size: 30px;
  margin-left: 320px;
  width: 40px;
}

.comments-form .emoji-btn:hover {
  color: white;
}

.comments-form .create-link-btn {
  margin-left: 5px;
}
.comments-form .create-link-btn:hover {
  color: white;
}



.comments-form::after {
  content: "";
  clear: both;
  display: table;
}

.comments-section .comment {
  border: 1px solid white;
  border-top: 1px solid #ccc;
  clear: both; 
  padding: 12px;
  width: 100%;
}

.comments-section .comment::after {
  content: "";
  clear: both;
  display: table;
}

.comments-section .comment:hover {
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;
  background: #F7F9F9;
}

.comments-section .comment:last-of-type {
  border-bottom: 1px solid #ccc;
}

.comment figure {
  display: block;
  float: left;
  margin-right: 12px;
}

.comment figure img {
  border: 1px solid #ccc;
  width: 50px;
}

.comment > div {
  
}

.comment header {
  justify-content: space-between;
  width: 100%;
}

.comment header a {
  float: left;
  font-weight: bold;
}

.comment header span {
  color: gray;
  float: right;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.comment > div .text {
  clear: both;
  margin-left: 62px; 
  white-space: pre-wrap;
  width: calc(100% - 62);
}

.comment > div .text blockquote {
  border-left: 10px solid #ccc;
  font-size: 14px;
  padding: 10px;
}

.comment > div .text blockquote div:first-child {
  font-style: italic;
  margin-bottom: 10px;
}

.comment footer {
  color: #444;
  font-size: 12px;
  font-style: italic;
  margin: 20px 0 0 62px;
  position: relative;
}

.comment footer span {
  position: absolute;
  bottom: -21px;
}

.comment footer button {
  background: #ccc;
  border: 0;
  color: white;
  cursor: pointer;
  display: block;  
  float: right;
  font-size: 10px;
  height: 20px;  
  margin-left: 10px;
  width: 20px;
}

.comment footer button:hover {
  background: gray;
}


.comment footer .flag-set {
  background: var(--red);
}

.comment footer .flag-set:hover {
  background: maroon;
}

.comment footer .flag-unset {
  background: #ccc;
}

.comment footer .flag-unset:hover {
  background: gray;
}



.create-link {
  background: #eee;
  border-top: 1px solid #888;
  border-radius: 2px;
  box-shadow: 0 5px 5px 0;
  display: none;
  left: 150px;
  padding: 20px;
  position: absolute;
  top: -150px;
}

.create-link input {
  border: 1px solid #666;
  border-radius: 2px;
}

.create-link button {
  border: 0;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  width: 100px;
}

.create-link button:last-child {
  background: #5cb85c;
  float: right;
}

.create-link button:last-child:hover {
  background: forestgreen;
}

.create-link button:nth-child(2) {
  background: #444;
}

.create-link button:nth-child(2):hover {
  background: #222;
}

.tooltip-top {
  position: relative;
  display: inline-block;
}

.tooltip-top .tooltip-top-text {
  visibility: hidden; 
  height: 35px;
  width: 150px;
  background-color: white;
  border-top: 1px solid #ccc;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.2);
  color: black;
  font-size: 13px;
  text-align: center;
  border-radius: 6px;
  opacity: 0;
  padding: 9px 0 6px 0;
  position: absolute;
  z-index: 1;
  top: -200%;
  transition: 0s opacity, visibility 0s;
  left: -20%;
  margin-left: -60px;
}

.tooltip-top:hover .tooltip-top-text {
  opacity: 1;
  visibility: visible;
  transition: .2s opacity, visibility .2s;
  transition-delay: 1s;
}


/**
 * External media
 */ 

#external-media {
  display: flex;
  justify-content: space-between;
  margin: 40px 0 23px 0;
  width: 100%; 
}

#external-media div{
  width: 620px
}

#external-media div a,
#external-media div a img{
  width: 100%
}

.steam-widget{
  display: block;
  height: 192px;
  width: 100%;
  margin-bottom: 20px;
  border:none
}

.bandcamp-widget{
  display: block;
  width: 100%;
  height: 288px;
  border: none;
  border-bottom: 1px solid #eee;
}


/**
 * Media section
 */ 
#media {
  margin-top: 40px; 
}
#media h3 {
  font-size: 22px;
  font-weight: lighter;
  margin-bottom: 5px;
}
#media img {
  width: 100%;
}

.media-item{
  border:1px solid gray;
  overflow:hidden;
  margin:0 40px 40px 0;
  position:relative;
  height:200px;
  width:400px;
  cursor:pointer
}

.media-item:nth-of-type(3n){
  margin-right:0
}
.show-media-item{
  border:1px solid gray;
  overflow:auto;
  margin-bottom:40px;
  position:relative;
  line-height:1.2;
  width:400px;
  cursor:pointer;
  height:auto
}

.media-caption{
  color:#eee;
  background:#000;
  background: rgba(0,0,0,.75);
  padding: 0 0 5px 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30px
}

.show-caption{
  color: #fff;
  background: #000;
  background: rgba(0,0,0,.75);
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto
}

.caption-expand{
  color: #fff;
  background: #000;
  background: rgba(0,0,0,.75);
  padding: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px

}



.legal { 
  margin: 100px 0 100px 100px; 
}

.legal h2 { 
  margin: 20px 0; 
}

.legal p { 
  margin: 0 0 10px 0; 
}

.legal ul {
  margin: 0 0 16px  40px;
}

.error-404 {
  padding-top: 200px;
  text-align: center;
}

.error-404 h2 {
  font-size: 28px;
  padding: 40px 0 20px 0;
}

.error-404 p {
  font-size: 18px;
}

.error-404 a {
  display: block;
  margin: 0 auto;
  width: 400px;
}

.error-404 a img {
  width: 100%;
}


/**
 * Mobile
 */

@media only screen and (max-width:1200px) {
  .sections {
    width: 800px;
  }
  .article {
    width: 380px;
  }
  .article:nth-of-type(3n) {
    margin-right: 40px;
  }
  .article:nth-of-type(2n) {
    margin-right: 0;
  }
  .topsection div .cta {
    font-size: 18px;
  }
  .topsection div .cta div:first-child {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .topsection div .cta .play-button i {
    font-size: 75px;
  }
  .topsection div .cta .purchase-game {
    margin-top: 100px;
  }
  #external-media {
    display: block;
  }
  #external-media div {
    width: 100%;
  }
  #external-media div:first-child {
    margin-bottom: 40px;
  }
  #external-media div:last-child {
    width: 100%;
  }
  .bandcamp-widget {
    width: 100%;
    margin: 0 auto;
  }
  .media-item {
    width: 380px;
  }
  .media-item:nth-of-type(3n) {
    margin-right: 40px;
  }
  .media-item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media only screen and (max-width:800px) {
  #header > div {
    width: 100%;
  }
  #header > div h1 {
    display: inline-block;
    width: 200px;
  }
  .logo {
    width: 200px;
  }
  .sections {
    width: 100%;
  }
  .topsection div .cta {
    font-size: 14px;
  }
  .topsection div .cta div:first-child {
    font-size: 22px;
  }
  #news > * {
    width: 100%;
  }
  .article {
    margin-right: 0;
  }
  .article:nth-of-type(3n) {
    margin-right: 0;
  }
  #media > * {
    width: 100%;
  }
  .media-item {
    margin-right: 0;
  }
  .media-item:nth-of-type(3n) {
    margin-right: 0;
  }
}
@media only screen and (max-width:500px) {
  #header {
    background: black;
    height: 100px;
    position: static;
  }
  #header > div {
    padding: 0 20px;
  }
  #navigation {
    display: none;
  }
  .mobile-menu-button {
    color: white;
    display: block;
    float: right;
    margin: 35px 20px 0 0;
    z-index: 99;
  }
  .overlay {
    display: none;
  }
  #reviews {
    display: none;
  }

  .youtube-modal {
    height: 400px;
    width: 100%;
  }
  .close-btn {
    right: 0;
    top: -80px;
  }
}

/* Required for mobile menu */
@media screen and (max-height: 450px) {
  #mobile-menu a {font-size: 20px}
  #mobile-menu .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}
